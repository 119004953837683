<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.41432 2.77042V0.999268L11.4343 3.95031L8.41432 6.90135V5.1302C6.46022 5.1302 4.87201 6.71575 4.87201 8.67251C4.87201 10.6293 6.45757 12.2148 8.41432 12.2148C10.3684 12.2148 11.9566 10.6293 11.9566 8.67251H14.3164C14.3164 11.9311 11.6729 14.5746 8.41432 14.5746C5.15306 14.5746 2.51224 11.9311 2.51224 8.67251C2.51224 5.41125 5.15306 2.77042 8.41432 2.77042Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconArrowRotateRight'
}
</script>
